import { SVGAttributes } from 'react';

export function KonohaSing(props: SVGAttributes<SVGGElement>) {
  return (
    <svg
      id="Icons"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {
          '\n\t.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n'
        }
      </style>
      <path d="M29.4,15.1l-8.9-3.5l-3.5-8.9C16.8,2.3,16.4,2,16,2s-0.8,0.3-0.9,0.6l-3.5,8.9l-8.9,3.5C2.3,15.2,2,15.6,2,16 s0.3,0.8,0.6,0.9l8.9,3.5l3.5,8.9c0.2,0.4,0.5,0.6,0.9,0.6s0.8-0.3,0.9-0.6l3.5-8.9l8.9-3.5c0.4-0.2,0.6-0.5,0.6-0.9 S29.7,15.2,29.4,15.1z M16,19c-1.7,0-3-1.3-3-3s1.3-3,3-3s3,1.3,3,3S17.7,19,16,19z" />
      <path d="M24.9,11.2L24.9,11.2l2.2-5c0.2-0.4,0.1-0.8-0.2-1.1c-0.3-0.3-0.7-0.4-1.1-0.2l-5,2.2l0,0C21.6,8.9,23.1,10.4,24.9,11.2z" />
      <path d="M25,20.8L25,20.8c-1.9,0.7-3.4,2.2-4.1,4.1l0,0l5,2.2c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.5-0.1,0.7-0.3 c0.3-0.3,0.4-0.7,0.2-1.1L25,20.8z" />
      <path d="M7.1,20.8L7.1,20.8l-2.2,5c-0.2,0.4-0.1,0.8,0.2,1.1c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.3,0,0.4-0.1l5-2.2l0,0 C10.4,23.1,8.9,21.6,7.1,20.8z" />
      <path d="M7,11.2L7,11.2c1.9-0.7,3.4-2.2,4.1-4.1l0,0l-5-2.2C5.8,4.7,5.4,4.8,5.1,5.1C4.8,5.4,4.7,5.8,4.9,6.2L7,11.2z" />
    </svg>
  );
}
